.btn {
  @apply flex-none w-auto px-4 py-1 text-sm font-semibold leading-6 text-black bg-yellow-300 border border-transparent rounded cursor-pointer ;
}

.btn:hover {
  @apply  bg-white hover:text-black;
}

.btn:focus {
  @apply focus:ring-offset-white focus:ring-white focus:outline-none;
} 