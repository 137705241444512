@font-face {
    font-family: "peacocksans";
    src: url(fonts/peacocksans-book.woff2) format("woff2"), url(fonts/peacocksans-book.woff) format("woff");
    font-weight: 100;
    font-display: swap
}

@font-face {
    font-family: "peacocksans";
    src: url(fonts/peacocksans-medium.woff2) format("woff2"), url(fonts/peacocksans-medium.woff) format("woff");
    font-weight: normal;
    font-display: swap
}

@font-face {
    font-family: "peacocksans";
    src: url(fonts/peacocksans-bold.woff2) format("woff2"), url(fonts/peacocksans-bold.woff) format("woff");
    font-weight: bold;
    font-display: swap
}