@tailwind base;
@tailwind components;
@tailwind utilities;

@import "/src/styles/buttons.css";
@import "/src/styles/fonts.css";

body {
  background-color: black;
}

.input-checkbox:checked ~ label {
  border-color: #f6e05e;
}

.input-checkbox:checked ~ label > div {
  visibility: visible;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Body--close {
  overflow: unset;
}
